<template>
  <v-container class="page-container" fill-height fluid grid-list-xl
  >
    <v-layout wrap id="configuration">
      <!-- Header title -->
      <h1 class="page-title text-xs-left mb-3 w-100">{{ pageTitle }}</h1>

      <!-- Add goal button -->
      <div class="flex-display flex-row flex-justify-end mb-3 w-100">
        <button class="btn add-new" @click="showAddCourseDialog('add',null)"><em
            class="v-icon mdi mdi-plus"></em> {{ addButtonText }}
        </button>
      </div>

      <!-- Goals list -->
      <div class="w-100" v-if="isReady">
        <draggable :list="courses" @end="onEnd">
          <transition-group>
            <div class="item-container"
                 v-for="(course, index) in courses"
                 :key="course.id"
            >
              <span class="m-l-minus-25 pr-4">{{ index + 1 }}</span>
              <em class="fa fa-arrows cursor-move position-absolute pr-4"></em>
              <p class="item-text w-100 position-relative pl-4">{{ course.title }}</p>
              <div class="item-actions">
                <button class="btn-edit" @click="showAddCourseDialog('edit', index)">Edit</button>
                <button class="btn-delete ml-2" @click="deleteCourse(course)">Delete</button>
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>

      <!-- Add goal dialog -->
      <add-course v-if="addCourseDialog"
                      :visible="addCourseDialog"
                      :actionType="actionType"
                      :courses="courses"
                      :exercises="exercises"
                      :value="selectedCourse"
                      @refresh-course="getCourses"
                      v-on:submitCourse="addCourse"
                      v-on:hide="hideAddCourseDialog"
      >
      </add-course>
    </v-layout>

  </v-container>
</template>

<script>
import AddCourse from "../components/configuration/AddCourse";
import draggable from 'vuedraggable'
import routeHelper from "@/helpers/app/routeHelper";

export default {
  'name': 'SelfCareCourses',
  components: {
    AddCourse,
    draggable
  },
  data: () => ({
    pageTitle: 'Courses',
    addButtonText: 'New Course',
    isReady: true,
    addCourseDialog: false,
    deleteCourseDialog: false,
    actionType: '',
    courses: [],
    exercises: [],
    selectedIndex: null,
    selectedCourse: {
      title: '',
      status: '',
      keywords: []
    },
  }),
  created() {
    this.getCourses();
  },
  methods: {
    getCourses() {
      let loader = this.$loading.show();
      this.$http.get(this.url + 'self_care/courses').then(response => {
        loader.hide();
        this.courses = response.data.courses;
        this.exercises = response.data.exercises;
      }).catch(error => {
        loader.hide();
        this.$toastr('error', error.response.data.message, 'Error');
      })
    },

    showAddCourseDialog(action, index) {
      this.addCourseDialog = true;
      this.actionType = action;
      this.selectedIndex = index;
      if (this.selectedIndex === null) {
        this.resetSelectedCourse();
      } else {
        this.selectedCourse = this.courses[index];
      }
    },
    deleteCourse(course) {
      let confirmDelete = confirm("Are you sure to delete this course?");
      if (confirmDelete !== true) {
        return;
      }
      let loader = this.$loading.show();
      this.$http.delete(this.url + 'self_care/course', {
        data: {
          course_id: course.id
        }
      }).then(response => {
        loader.hide();
        this.getCourses();
        this.$toastr('success', response.data.message, '');
      }).catch(error => {
        loader.hide();
        this.$toastr('error', error.response.data.message, '');
      });
    },
    hideAddCourseDialog() {
      this.resetSelectedCourse();
      this.addCourseDialog = false;
    },
    resetSelectedCourse() {
      this.selectedIndex = null;
      this.selectedCourse = {
        title: '',
        status: '',
        keywords: []
      }
    },
    onEnd(evt) {
      this.courses.map((course, index) => {
        course.order = index + 1;
      })
      this.$http.put(this.url + routeHelper.sortCourseApi, {
        sorted_course: this.courses
      }).then(response => {
        this.$toastr('success', response.data.message, '');
      })
    },
    // Update the view after course submission
    addCourse(course) {
      this.selectedIndex === null ? this.courses.push(course) : this.courses[this.selectedIndex] = course;
      this.hideAddCourseDialog();
    }
  }
}
</script>
<style scoped>
.m-l-minus-25 {
  margin-left: -25px;
}


</style>
